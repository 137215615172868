import {get} from "../../../Utils/Crypto";

const DEVELOPMENT_MODE = window.location.href.includes("localhosts") || window.location.href.includes('vendor.dev.eka.io');

const PROD_MODE = window.location.href.includes('localhosts') || window.location.href.includes('https://vendor-staging.netlify.app/');;

let api, luna, token, track;

if (DEVELOPMENT_MODE) {
	api = "https://api.dev.eka.io/vendor/";
	track = "https://track.expanso.in/vendor/";
	luna = "luna-staging";
	token = () => get("refresh_token");
} else if (PROD_MODE) {
	api = "https://api.prod.eka.io/vendor/";
    track = "https://track.expanso.in/vendor/";
    luna = "luna";
    token = () => get("refresh_token");
} else {
	api = "https://heimdall.eka.io/vendor/";
	luna = "luna";
	token = () => get("refresh_token");
}

if(window.location.href.includes('dev-vendor-dice-tech')) {
	api = 'https://integration.dev.eka.io/vendor/'
}

track = "https://track.eka.io/vendor/";


export {
	api, luna, DEVELOPMENT_MODE, token, track
}
